/** @jsx jsx */
import ExhibitorPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorPage';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function ExhibitorTemplate({
  data: {
    bottlebooks,
    event,
    exhibitor,
    previous,
    next,
    site: { siteMetadata },
  },
  pageContext,
  location,
  navigate,
}) {
  const bb_exhibitor = bottlebooks?.event?.exhibitor;
  const bb_registration = bottlebooks?.event?.registrations?.nodes?.[0];
  const bb_registeredBrands = bottlebooks?.event?.registeredBrands?.nodes;
  const bb_products = bb_exhibitor?.products?.nodes;
  const enhancedExhibitor = {
    ...exhibitor,
    ...bb_exhibitor,
    ...bb_registration,
    brands: bb_registeredBrands,
    products: exhibitor.products?.map((product, index) => {
      if (!bb_products?.length) return product;
      return { ...product, ...bb_products[index] };
    }),
    // This is used to filter out exhibitors. Only exhibitors with a stand are displayed. We have to clean this up.
    stand:
      exhibitor?.stand?.titleWithPrefix === 'Stand -' ? '' : exhibitor?.stand,
  };
  return (
    <ExhibitorPage
      {...pageContext}
      // Make sure the page is remounted on a different href (i.e. a different query).
      // QUESTION: Why is this actually necessary? Because of the page's state?
      key={location.href}
      event={event}
      exhibitor={enhancedExhibitor}
      products={enhancedExhibitor && enhancedExhibitor.products}
      previous={previous}
      next={next}
      siteTitle={siteMetadata.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  query vinomed_ExhibitorTemplate(
    $id: String!
    $eventId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        registeredBrands(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            ...bb_ExhibitorPage_RegisteredBrands
          }
        }
        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            ...bb_Registration
          }
        }
        exhibitor(exhibitorId: $exhibitorId) {
          ...bb_ExhibitorPage
        }
      }
    }

    exhibitor(id: { eq: $id }) {
      ...ExhibitorTemplate_Exhibitor
    }
    previous: exhibitor(id: { eq: $previousId }) {
      ...ChangeExhibitorLink
    }
    next: exhibitor(id: { eq: $nextId }) {
      ...ChangeExhibitorLink
    }
    ...ExhibitorTemplate_Query
  }

  fragment ExhibitorTemplate_Exhibitor on Exhibitor {
    ...ExhibitorPage
    event {
      ...ExhibitorPage_Event
    }
  }

  fragment ExhibitorTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
