import { graphql, useStaticQuery } from 'gatsby';

export default function useEventData() {
  const {
    exhibitors: { nodes: exhibitors },
    products: { nodes: products },
  } = useStaticQuery(QUERY);

  // Filter out
  const filteredExhibitors = exhibitors.filter(
    (exhibitor) => !!exhibitor?.stand?.titleWithPrefix
  );

  const filteredProducts = products.filter(
    (product) => !!product?.stand?.titleWithPrefix
  );

  return { exhibitors: filteredExhibitors, products: filteredProducts };
}

const QUERY = graphql`
  fragment SearchResult_Product on Product {
    shortName
    ...ProductImageSmaller_list
    exhibitor {
      name
    }
    producer {
      name
    }
    ...useLink_Product
  }

  fragment SearchResult_Brand on AbstractBrand {
    name
    countryName: country(full: true)
    region
    city
    ...BrandLogoSmaller_list
    ...useLink_exhibitor_AbstractBrand
  }

  query vinomed_SiteSearch {
    exhibitors: allExhibitor {
      nodes {
        brandId
        locale
        ...SearchResult_Brand
        ...Search_Exhibitor
        stand {
          titleWithPrefix: title(prefix: "Stand ")
        }
      }
    }
    products: allProduct {
      nodes {
        productId
        locale
        ...SearchResult_Product
        ...Search_Product
        stand {
          titleWithPrefix: title(prefix: "Stand ")
        }
      }
    }
  }
`;
