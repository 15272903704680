import {
  catalogs as baseCatalogs,
  defaultLocale,
  ensureModule,
} from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
import { i18n } from '@lingui/core';
import de from '../../../../locales/de';
import en from '../../../../locales/en';
import es from '../../../../locales/es';
import fr from '../../../../locales/fr';

const catalogs = {
  ...baseCatalogs,
  de: {
    messages: { ...baseCatalogs.de.messages, ...ensureModule(de).messages },
  },
  en: {
    messages: { ...baseCatalogs.en.messages, ...ensureModule(en).messages },
  },
  es: {
    messages: { ...baseCatalogs.es.messages, ...ensureModule(es).messages },
  },
  fr: {
    messages: { ...baseCatalogs.fr.messages, ...ensureModule(fr).messages },
  },
};

export { locales } from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
export { defaultLocale };

export function load(locale) {
  if (!locale) return {};
  const catalog = catalogs[locale];
  i18n.load(locale, catalog.messages);
  return catalog.messages;
}
