/** @jsx jsx */
import ExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ExhibitorsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, exhibitors, site } = afterQuery(data);
  const { filterSeekingDistribution, filterHasDistribution } = event || {};
  return (
    <ExhibitorsPage
      {...pageContext}
      // Make sure the page is remounted on a different href (i.e. a different query).
      // QUESTION: Why is this actually necessary? Because of the page's state?
      key={location.href}
      event={event}
      exhibitors={exhibitors}
      siteTitle={site?.siteMetadata?.title}
      facetConfig={{
        filterSeekingDistribution,
        filterHasDistribution,
        ...pageContext,
      }}
      location={location}
      navigate={navigate}
    />
  );
}

function afterQuery({ bottlebooks, event, firstExhibitors, exhibitors, site }) {
  const bb_event = bottlebooks?.event;
  const bb_exhibitors = bb_event?.exhibitors?.nodes;

  // Enrich the first exhibitors with better images and base64 previews.
  const combinedExhibitors = exhibitors.nodes?.map((exhibitor, index) => {
    const refinedData = firstExhibitors?.nodes[index];
    const bb_exhibitor = bb_exhibitors?.find(
      ({ exhibitorId }) => exhibitorId === exhibitor.exhibitorId
    );
    return deepMerge({}, exhibitor, bb_exhibitor, refinedData);
  });

  // Filter out anyone without a stand
  const filteredExhibitors = combinedExhibitors.filter(
    (exhibitor) => !!exhibitor?.stand?.titleWithPrefix
  );

  const withoutStands = filteredExhibitors.map((exhibitor) => {
    return {
      ...exhibitor,
      stand: '',
    };
  });

  return {
    event,
    site,
    exhibitors: withoutStands,
  };
}

export const pageQuery = graphql`
  query vinoMed_ExhibitorsTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        exhibitors {
          nodes {
            exhibitorId
            ...bb_ExhibitorsPage
          }
        }
      }
    }

    event(locale: { eq: $localeString }) {
      ...ExhibitorsTemplate_Event
    }
    firstExhibitors: allExhibitor(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ExhibitorsPage_first
      }
    }
    exhibitors: allExhibitor(filter: $filter, sort: $sort) {
      nodes {
        exhibitorId
        ...ExhibitorsPage
      }
    }
    ...ExhibitorsTemplate_Query
  }

  fragment ExhibitorsTemplate_Event on Event {
    filterHasDistribution
    filterSeekingDistribution
    ...ExhibitorsPage_Event
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
