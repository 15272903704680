// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-[locale]-favorites-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/pages/[locale]/favorites.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-[locale]-favorites-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-favorites-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/pages/favorites.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-favorites-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-q-exhibitor-id-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/pages/q/[exhibitorId].js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-q-exhibitor-id-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-q-exhibitor-id-product-id-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/pages/q/[exhibitorId]/[productId].js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-q-exhibitor-id-product-id-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-tasting-list-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/pages/tastingList.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-pages-tasting-list-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-templates-landing-template-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/templates/LandingTemplate.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-templates-landing-template-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-templates-producers-template-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event-pages/src/templates/ProducersTemplate.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-pages-src-templates-producers-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-pages-templates-exhibitor-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event-pages/templates/ExhibitorTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-pages-templates-exhibitor-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-pages-templates-exhibitors-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event-pages/templates/ExhibitorsTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-pages-templates-exhibitors-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-pages-templates-producer-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event-pages/templates/ProducerTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-pages-templates-producer-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-pages-templates-product-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event-pages/templates/ProductTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-pages-templates-product-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-pages-templates-products-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event-pages/templates/ProductsTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-pages-templates-products-template-js" */)
}

