/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Button } from '@bottlebooks/gatsby-theme-base';
import ProductSampleRequestConfirmation from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductActions/ProductSampleRequestConfirmation';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import ProductSampleRequestDialog from './ProductSampleRequestDialog';

export default function ProductSampleRequestButton({ product, ...rest }) {
  const [visibleDialogName, setVisibleDialogName] = React.useState('');
  return (
    <React.Fragment>
      <Button
        onClick={() => setVisibleDialogName('form')}
        variant="primary"
        sx={{
          paddingX: 4,
          paddingY: 2,
          marginRight: 2,
          alignItems: 'center',
        }}
        {...rest}
      >
        Request individual sample
      </Button>

      {visibleDialogName === 'form' && (
        <ProductSampleRequestDialog
          product={product}
          onSubmit={({ success }) => {
            if (!success) {
              setVisibleDialogName('');
              return console.error('Request failed');
            }
            setVisibleDialogName('confirm');
          }}
          onDismiss={() => setVisibleDialogName('')}
        />
      )}
      {visibleDialogName === 'confirm' && (
        <ProductSampleRequestConfirmation
          exhibitor={product.exhibitor}
          onDismiss={() => setVisibleDialogName('')}
        />
      )}
    </React.Fragment>
  );
}

export const fragment = graphql`
  fragment lwf_bb_ProductSampleRequestButton on Bottlebooks_Product {
    productId
    ... on Bottlebooks_ExtendedProduct {
      #collectionId: eventId
      exhibitor {
        exhibitorId
        name
      }
    }
  }
`;
