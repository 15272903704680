/** @jsx jsx */
import ProductPage from '@bottlebooks/gatsby-theme-event/src/components/EventProductPage/EventProductPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ProductTemplate({
  data: {
    bottlebooks,
    product,
    previous,
    next,
    site: { siteMetadata },
  },
  pageContext,
  location,
  navigate,
}) {
  const event = bottlebooks?.event;
  // Pick out the registeredProduct data, so we can mix it into the base product.
  const registeredProduct = bottlebooks?.event?.registeredProducts?.nodes?.[0];
  const exhibitor = bottlebooks?.event?.registrations?.nodes?.[0];
  const extendedProduct = deepMerge(
    {},
    { ...product, exhibitor: { ...product?.exhibitor, ...exhibitor } },
    bottlebooks?.event?.product,
    registeredProduct,
    registeredProduct?.product,
    // Ensure that the new info sheet route overrides the old one
    { infoSheet: registeredProduct.infoSheet },
    // This is used to filter out exhibitors. Only exhibitors with a stand are displayed. We have to clean this up.
    {
      stand: product?.stand?.titleWithPrefix === 'Stand -' ? '' : product.stand,
    }
  );
  const producerProducts = extendedProduct.producer?.products || [];
  const otherProducts = producerProducts
    .filter((other) => other.id !== product.id)
    .slice(0, 6);

  return (
    <ProductPage
      {...pageContext}
      event={event}
      product={extendedProduct}
      exhibitor={exhibitor}
      otherProducts={otherProducts}
      otherProductsName={extendedProduct?.producer?.name}
      previous={previous}
      next={next}
      siteTitle={siteMetadata.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  query vinomed_ProductTemplate(
    $id: String!
    $productId: ID!
    $eventId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        eventId
        # Get the IDs and other data out of registeredProducts.
        registeredProducts(
          filter: {
            productId: { eq: $productId }
            partnerId: { eq: $exhibitorId }
          }
        ) {
          nodes {
            ...EventProductPage_RegisteredProduct
            ...new_bb_ProductInfoSheetButton
          }
        }
        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            # This is a quick fix to get the data onto the page
            ...bb_Registration
          }
        }
        product(productId: $productId, exhibitorId: $exhibitorId) {
          ...bb_EventProductPage
          ...lwf_ProductActions
          ... on Bottlebooks_Wine {
            wineType(format: LOCALIZED)
          }
        }
      }
    }
    product(id: { eq: $id }) {
      id
      ...EventProductPage
      producer {
        name
        products {
          ...EventProductPage_Related
        }
      }
    }
    previous: product(id: { eq: $previousId }) {
      ...ChangeProductLink
    }
    next: product(id: { eq: $nextId }) {
      ...ChangeProductLink
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
