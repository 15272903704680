/** @jsx jsx */
import {
  Box,
  Button,
  Container,
  Flex,
  Link,
  StarIcon,
} from '@bottlebooks/gatsby-theme-base';
import useFavorites from '@bottlebooks/gatsby-theme-event/src/components/Favorites/useFavorites';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { jsx } from 'theme-ui';
import { ShoppingCartIndicator } from '@bottlebooks/gatsby-theme-event/src/components/Shop/ShoppingCartMenu';
import SiteSearch from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/SiteSearch';
import Logo from './DesktopLogo';
import MenuItem from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @param {string} opt.landingPage
 * @param {boolean} opt.isScrolledDown
 * @param {string} opt.locale
 * @returns
 */
export default function MainMenuDesktop({
  menuItems,
  landingPage,
  isScrolledDown,
  locale,
  ...rest
}) {
  const { i18n } = useLingui();
  const { favorites } = useFavorites();
  const link = useLink();
  return (
    <Container fluid sx={{ maxWidth: 'container.fluid' }} {...rest}>
      <Flex>
        <Box sx={{ marginRight: 3 }}>
          <Logo to={landingPage} isScrolledDown={isScrolledDown} />
        </Box>
        <Flex direction="column" sx={{ flexGrow: 1 }}>
          <Flex align="center" sx={{ height: '64px' }}>
            <Box sx={{ position: 'relative' }}>
              <SiteSearch
                variant="large"
                locale={locale}
                placeholder={i18n._(t`Search for producer or wine…`)}
                sx={{ width: '300px', marginLeft: 2 }}
              />
            </Box>
            <Flex
              direction="row-reverse"
              align="center"
              sx={{ flexGrow: 1 }}
              {...rest}
            >
              {process.env.GATSBY_SNIPCART_API_KEY && <ShoppingCartIndicator />}
              <Link
                to={link.favorites()}
                variant="text"
                sx={{ marginRight: 2, position: 'relative' }}
              >
                <StarIcon sx={{ width: '30px', height: '30px' }} />
                {!!favorites.length && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '25px',
                      top: '6px',
                      color: 'primary',
                      marginTop: '-14px',
                    }}
                  >
                    {favorites.length}
                  </Box>
                )}
              </Link>
              <Box sx={{ marginRight: 3 }}>
                <Link to="/en" sx={{ marginRight: 2 }}>
                  EN
                </Link>
                <Link to="/fr" sx={{ marginRight: 2 }}>
                  FR
                </Link>
                <Link to="/es">ES</Link>
              </Box>
            </Flex>
          </Flex>
          <Flex
            align="center"
            sx={{ justifyContent: 'space-between', height: '64px' }}
          >
            <Box>
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  {...item}
                  sx={{ width: ['48%', '48%', 'initial', 'initial'] }}
                />
              ))}
            </Box>
            <Box>
              <Button
                variant="primary"
                target="_blank"
                href={
                  locale === 'fr'
                    ? 'https://vinomed.myaccessbadge.net/index.php?&lang=FR'
                    : 'https://vinomed.myaccessbadge.net/index.php?&lang=UK'
                }
              >
                <Trans>Visitor's Badge</Trans>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}
