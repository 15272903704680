/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Button,
  Flex,
  SemiTitle,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import { Global } from '@emotion/core';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import { jsx } from 'theme-ui';

export default function ProductSampleRequestConfirmation({
  exhibitor,
  onDismiss,
  ...rest
}) {
  return (
    <Dialog
      isOpen
      onDismiss={onDismiss}
      aria-label="Book a meeting"
      sx={{
        borderRadius: 'default',
        '&&': {
          width: ['45vw', '40vw', '35vw', 400],
          padding: 2,
        },
      }}
      {...rest}
    >
      <Global styles={{ '[data-reach-dialog-overlay]': { zIndex: 1 } }} />

      <Box sx={{ marginBottom: 4, padding: 3 }}>
        <Title>Success!</Title>
        <SemiTitle>The wine request is on it's way.</SemiTitle>

        <Flex sx={{ marginTop: 4 }}>
          <Button sx={{ marginLeft: 2 }} onClick={onDismiss} variant="outline">
            Close
          </Button>
        </Flex>
      </Box>
    </Dialog>
  );
}
