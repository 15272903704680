/**  @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx, ThemeProvider } from 'theme-ui';
import BaseLayout from '@bottlebooks/gatsby-theme-event/src/components/Layout/BaseLayout';
import Footer from '@bottlebooks/gatsby-theme-event/src/components/Layout/Footer';
import FooterLogo from '@bottlebooks/gatsby-theme-event/src/components/Layout/FooterLogo';
import Header from '@bottlebooks/gatsby-theme-event/src/components/Layout/Header';
import Logo from '@bottlebooks/gatsby-theme-event/src/components/Layout/Logo';
import MainMenu, {
  useMenuQuery,
} from '@bottlebooks/gatsby-theme-event/src/components/Layout/MainMenu';
import useEventTheme from '@bottlebooks/gatsby-theme-event/src/components/Layout/useEventTheme';
// import { useScroll } from './useScroll';

export default function Layout({ children, locale, ...rest }) {
  const landingPage = useLandingPage({ locale });
  const theme = useEventTheme();

  // Scrolling is currently disabled because it causes significant
  // peformance degredation on Safari. It should only be reactivated
  // once this issue has been resolved.
  // const { showHeader, isScrolledDown } = useScroll();
  const isScrolledDown = false;
  const showHeader = true;
  return (
    <ThemeProvider theme={theme}>
      <BaseLayout {...rest}>
        <Header
          homePath={landingPage}
          isScrolledDown={isScrolledDown}
          showHeader={showHeader}
          locale={locale}
          sx={{ width: '100%' }}
        >
          <MainMenu
            locale={locale}
            isScrolledDown={isScrolledDown}
            landingPage={landingPage}
          />
        </Header>

        <Box as="main">{children}</Box>

        <Footer sx={{ marginTop: 'auto' }}>
          <FooterLogo to={landingPage} />
        </Footer>
      </BaseLayout>
    </ThemeProvider>
  );
}
function useLandingPage({ locale }) {
  return `/${locale}`;
}
