/** @jsxRuntime classic */
/** @jsx jsx */
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  CloseIcon,
  Flex,
  Grid,
  SemiTitle,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import { Global } from '@emotion/core';
import { Trans } from '@lingui/macro';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { jsx } from 'theme-ui';
import createPersistedState from 'use-persisted-state';
import * as yup from 'yup';
import AvatarSubstitute from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandContacts/AvatarSubstitute';

const useProfileState = createPersistedState('bottlebooks:profile');

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter a email.')
    .trim() // This is handy because when calling schema.cast() you can auto-trim the string.
    // It also checks the min length after trimming.
    .min(3, 'Please provide at least 3 characters.'),
  name: yup
    .string()
    .required('Please enter a name.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
  telephone: yup
    .string()
    .required('Please enter a name.')
    .trim()
    .matches(phoneRegExp, 'Phone number is not valid'),
  companyName: yup
    .string()
    .required('Please enter your company name.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
  street: yup
    .string()
    .required('Please enter the street.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
  city: yup
    .string()
    .required('Please enter the city.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
  zipCode: yup
    .string()
    .required('Please enter the zip code.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
  country: yup
    .string()
    .required('Please enter the country.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
  message: yup
    .string()
    .required('Please enter a message.')
    .trim()
    .min(3, 'Please provide at least 3 characters.'),
});

export default function ProductSampleRequestDialog({
  product,
  onDismiss,
  onSubmit,
  initialValues = {},
  ...rest
}) {
  const contact = { fullName: product.exhibitor?.name, companyName: '' };
  // Manage profile state locally until authentication is enabled
  const [profile, setProfile] = useProfileState({});
  const [sendSampleRequest] = useMutation(SEND_SAMPLE_REQUEST);
  async function sendRequest({
    email,
    name,
    telephone,
    companyName,
    street,
    city,
    zipCode,
    country,
    message,
  }) {
    setProfile({
      email,
      name,
      telephone,
      companyName,
      street,
      city,
      zipCode,
      country,
    });
    const { data } = await sendSampleRequest({
      variables: {
        collectionId: product.collectionId,
        companyId: product.exhibitor?.exhibitorId,
        message,
        requesterDetails: {
          email: email,
          name: name,
          telephone: telephone,
          sendSampleRequest,
          companyName: companyName,
          street: street,
          city: city,
          zipCode: zipCode,
          country: country,
        },
        productIds: [product.productId],
      },
    });
    onSubmit({ ...data?.result });
  }
  return (
    <Dialog
      isOpen
      onDismiss={onDismiss}
      aria-label="Request sample"
      sx={{
        position: 'relative',
        borderRadius: 'default',
        '&&': {
          margin: ['auto', null, null, '10vh auto'],
          width: ['100vw', '80vw', '75vw', 768],
          padding: 2,
        },
      }}
      {...rest}
    >
      <Button
        onClick={onDismiss}
        sx={{ position: 'absolute', top: 2, right: 2 }}
      >
        <CloseIcon />
      </Button>
      <Global styles={{ '[data-reach-dialog-overlay]': { zIndex: 1 } }} />
      <Formik
        initialValues={{
          email: '',
          name: '',
          telephone: '',
          companyName: '',
          street: '',
          city: '',
          zipCode: '',
          country: '',
          message: '',
          ...initialValues,
          ...profile,
        }}
        validationSchema={schema}
        validateOnMount={true}
        onSubmit={sendRequest}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Grid sx={{ gridTemplateColumns: '200px auto' }}>
              <Grid sx={{ gridColumn: ['1 / -1'] }}>
                <Flex direction={['column', null, 'row']}>
                  <Flex
                    className="bb-hover"
                    sx={{
                      minWidth: 80,
                      width: 80,
                      height: 80,
                      marginRight: 3,
                      border: '3px solid',
                      borderRadius: 'round',
                      borderColor: 'primary',
                      backgroundColor: 'white',
                      transition: 'transform',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AvatarSubstitute contact={contact} />
                  </Flex>

                  <Box sx={{ marginBottom: 3, flexGrow: 1 }}>
                    <SemiTitle>
                      <Trans>Request sample from</Trans>
                    </SemiTitle>
                    <Title>{contact.fullName}</Title>
                    <Text variant="small">{contact.companyName}</Text>
                  </Box>
                </Flex>
              </Grid>

              <SectionTitle index="1" sx={{ gridColumn: '1 / -1' }}>
                <Trans>Introduce yourself</Trans>
              </SectionTitle>
              <Grid sx={{ gridColumn: ['1 / -1', '2 / -1'] }}>
                <TextField
                  name="message"
                  as="textarea"
                  rows={5}
                  sx={{ width: '100%' }}
                  placeholder="Tell about who you are and why you are interested in this wine."
                >
                  <Trans>Introduction:</Trans>
                </TextField>
              </Grid>

              <SectionTitle index="2" sx={{ gridColumn: '1 / -1' }}>
                Shipping details
              </SectionTitle>

              <Grid sx={{ gridColumn: ['1 / -1', '2 / -1'] }}>
                <TextField
                  autoComplete="name"
                  name="name"
                  sx={{ width: '50ch' }}
                >
                  Delivery to:
                </TextField>

                <TextField
                  autoComplete="tel"
                  name="telephone"
                  sx={{ width: '50ch' }}
                >
                  Telephone (required for delivery):
                </TextField>

                <TextField
                  autoComplete="organization"
                  name="companyName"
                  sx={{ width: '50ch' }}
                >
                  Your company:
                </TextField>

                <TextField
                  autoComplete="street-address"
                  name="street"
                  sx={{ width: '50ch' }}
                >
                  Street:
                </TextField>
                <TextField
                  autoComplete="address-level1"
                  name="city"
                  sx={{ width: '50ch' }}
                >
                  City:
                </TextField>
                <TextField
                  autoComplete="postal-code"
                  name="zipCode"
                  sx={{ width: '50ch' }}
                >
                  Zip code:
                </TextField>
                <TextField
                  autoComplete="country-name"
                  name="country"
                  sx={{ width: '50ch' }}
                >
                  Country:
                </TextField>
                <TextField
                  disabled={initialValues.email ? true : false}
                  name="email"
                  sx={{ width: '50ch' }}
                >
                  Email:
                </TextField>
              </Grid>

              <Grid sx={{ gridColumn: ['1 / -1', '2 / -1'] }}>
                <Button
                  sx={{
                    marginLeft: 'auto',
                    ...(isSubmitting && { opacity: 0.5 }),
                  }}
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                >
                  <Trans>Send request</Trans>
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

function SectionTitle({ index, children, ...rest }) {
  return (
    <Flex
      gap={2}
      align="baseline"
      sx={{ borderBottom: 1, borderColor: 'borderSecondary' }}
      {...rest}
    >
      <Title
        sx={{
          color: 'lighterText',
          order: [0, 0],
          width: 25,
          flex: '0 0 auto',
        }}
      >
        {index}
      </Title>
      <SemiTitle sx={{ flex: '1 1 auto' }}>{children}</SemiTitle>
    </Flex>
  );
}

function TextField({ name, children, id = name, ...rest }) {
  return (
    <Box>
      <Box as="label" sx={{ display: 'block' }}>
        <Text variant="small">{children}</Text>
        <Field
          id={id}
          name={name}
          sx={{
            border: 1,
            borderColor: 'borderSecondary',
            paddingX: 2,
            paddingY: 1,
            display: 'block',
            maxWidth: '100%',
            fontFamily: 'body',
            fontSize: 'small',
          }}
          {...rest}
        />
      </Box>
      <ErrorMessage name={name} component={Error} sx={{ marginBottom: 1 }} />
    </Box>
  );
}

function Error({ children, ...rest }) {
  if (!children) return null;
  return (
    <Text sx={{ color: 'red', fontSize: 'small' }} {...rest}>
      {children}
    </Text>
  );
}

export const SEND_SAMPLE_REQUEST = gql`
  mutation sendSampleRequest(
    $collectionId: ID!
    $companyId: ID!
    $message: String!
    $requesterDetails: RequesterDetailsInput!
    $productIds: [ID]
  ) {
    result: sendSampleRequest(
      input: {
        collectionId: $collectionId
        companyId: $companyId
        message: $message
        requesterDetails: $requesterDetails
        productIds: $productIds
      }
    ) {
      code
      success
    }
  }
`;
