/** @jsx jsx */
import ProducerPage from '@bottlebooks/gatsby-theme-event/src/components/ProducerPage/ProducerPage';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
export default function ProducerTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, producer, previous, next, site } = afterQuery(data);

  return (
    <ProducerPage
      {...pageContext}
      // Make sure the page is remounted on a different href (i.e. a different query).
      // QUESTION: Why is this actually necessary? Because of the page's state?
      key={location.href}
      event={event}
      producer={producer}
      exhibitor={producer.exhibitor}
      products={producer && producer.products}
      previous={previous}
      next={next}
      siteTitle={site?.siteMetadata?.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  query vinomed_ProducerTemplate(
    $id: String!
    $eventId: ID!
    $producerId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        eventId
        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            ...bb_Registration_ProducerPage
          }
        }
        producer(exhibitorId: $exhibitorId, producerId: $producerId) {
          producerId
          products {
            nodes {
              productId
            }
          }
          ...bb_ProducerPage
        }
      }
    }

    producer(id: { eq: $id }) {
      ...ProducerTemplate_Producer
      products {
        productId
      }
    }
    previous: producer(id: { eq: $previousId }) {
      ...ChangeProducerLink
    }
    next: producer(id: { eq: $nextId }) {
      ...ChangeProducerLink
    }
    ...ProducerTemplate_Query
  }

  fragment ProducerTemplate_Producer on Producer {
    ...ProducerPage
    event {
      ...ProducerPage_Event
    }
  }

  fragment ProducerTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, producer, previous, next, site }) {
  const bb_producer = bottlebooks?.event?.producer;
  const registrations = bottlebooks?.event?.registrations;
  const bb_products = bb_producer?.products?.nodes;
  const enhancedProducer = {
    ...producer,
    ...bb_producer,
    exhibitor: registrations?.nodes?.[0],
    products: producer.products?.map((product) => {
      if (!bb_products?.length) return product;
      const bb_product = bb_products.find(
        (bb_product) => bb_product.productId === product.productId
      );
      return {
        ...product,
        ...bb_product,
        // This is used to filter out exhibitors. Only exhibitors with a stand are displayed. We have to clean this up.
        stand: '',
      };
    }),
    // This is used to filter out exhibitors. Only exhibitors with a stand are displayed. We have to clean this up.
    stand: producer?.stand?.titleWithPrefix === 'Stand -' ? '' : producer?.stand,
  };
  return {
    producer: enhancedProducer,
    event: { eventId: bottlebooks?.event?.eventId },
    previous,
    next,
    site,
  };
}
