/** @jsx jsx */
import { gql } from '@apollo/client';
import ProductInfoSheetButton from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductActions/ProductInfoSheetButton';
import ProductSampleRequestButton from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductActions/ProductSampleRequestButton';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';

export default function ProductActions({ product }) {
  return (
    <React.Fragment>
      <ProductSampleRequestButton
        product={product}
        sx={{ marginRight: 2, marginBottom: 2 }}
      />
      <ProductInfoSheetButton product={product} sx={{ marginRight: 2 }} />
    </React.Fragment>
  );
}

export const fragment = graphql`
  fragment lwf_ProductActions on Bottlebooks_Product {
    ...bb_ProductInfoSheetButton
    ...lwf_bb_ProductSampleRequestButton
  }
`;

ProductActions.fragment = gql`
  fragment ProductActions on Product {
    ...ProductInfoSheetButton
  }
  ${ProductInfoSheetButton.fragment}
`;
