/** @jsx jsx */
import ProductsPage from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ProductsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, site, products } = afterQuery(data);
  const { filterPriceRange } = event || {};

  return (
    <ProductsPage
      {...pageContext}
      // Make sure the page is remounted on a different href (i.e. a different query).
      // QUESTION: Why is this actually necessary? Because of the page's state?
      key={location.href}
      event={event}
      products={products}
      siteTitle={site?.siteMetadata?.title}
      facetConfig={{
        filterPriceRange,
        ...pageContext,
      }}
      location={location}
      navigate={navigate}
    />
  );
}

function afterQuery({ bottlebooks, event, firstProducts, products, site }) {
  const bb_event = bottlebooks?.event;
  const bb_products = bb_event?.registeredProducts?.nodes;

  const combinedProducts = products.nodes?.map((product, index) => {
    const refinedData = firstProducts?.nodes[index];
    const bb_registeredProduct = bb_products?.find(
      ({ productId }) => productId === product.productId
    );
    return deepMerge(
      {},
      product,
      bb_registeredProduct?.product,
      // There is other data at this level, so we have to
      //explicitly pick each custom question
      { highlights: bb_registeredProduct?.highlights },
      refinedData
    );
  });

  // Filter out anyone without a stand
  const filteredProducts = combinedProducts.filter(
    (exhibitor) => !!exhibitor?.stand?.titleWithPrefix
  );

  // We don't want to show stands, so delete the info.
  const withoutStands = filteredProducts.map((exhibitor) => {
    return {
      ...exhibitor,
      stand: '',
    };
  });

  return {
    event,
    site,
    products: withoutStands,
  };
}

export const pageQuery = graphql`
  query vinomed_ProductsTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ProductFilterInput
    $sort: ProductSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        registeredProducts {
          nodes {
            productId
            product {
              ...bb_ProductSections
              ...bb_ProductFilters
              ...bb_ProductListRow
              ...bb_ProductRegion
              ...bb_ProductsPageBase
            }
            ...bb_ProductFilters_RegisteredProduct
          }
        }
      }
    }

    firstProducts: allProduct(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ProductsPage_first
      }
    }
    products: allProduct(filter: $filter, sort: $sort) {
      nodes {
        productId
        slug
        exhibitor {
          slug
        }
        ...ProductsPage
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProductsPage_Event
      filterPriceRange
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
